@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0;
}

* {
  font-family: 'Roboto', sans-serif!important;
}

.leaflet-control-attribution {
  display: none;
}

.line-number {
  margin:0 auto;
  display:block;
  font-size:15px;
  font-weight:700
}

.vehicle-marker {
  box-shadow:1px 4px 7px #8a8a8a;
  position:relative;
  border-radius:15px;
  display:flex;
  outline:none;
  align-items:center;
  padding:0 6px!important;
}

.stop_marker {
  width: 5px;
  height: 5px;
  padding: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  outline: none;
  border-radius: 18px;
}

.stop_marker_active {
  width: 3px;
  height: 3px;
  padding: 3px;
  background-color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  outline: none;
  border-radius: 18px;
}

.leaflet-marker-icon.vehicle {
  transition: transform .3s linear;
}

.leaflet-marker-icon, path.leaflet-interactive {
  animation: fadein .4s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

path.leaflet-interactive {
  pointer-events: none!important;
}

:root {
  --rsbs-max-w: 640px;
  --rsbs-mr: auto;
  --rsbs-ml: auto;
}

[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow: rgb(0 0 0 / 30%) 0px -2px 16px!important;
}